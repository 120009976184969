import Hammer from 'hammerjs'

/**
 * 初始化缩放 拖动
 * @param {HTMLElement} element - 当前要进行缩放的 dom 元素
 * @param {object} options - 配置项
 * @returns {Function} reset - 返回一个方法，用于重置元素的原始状态
 *
 *  minScale - 最小缩放倍数
 *  maxScale - 最大缩放倍数
 */
export function initPinchZoom(element, options) {
  options = {
    minScale: options.minScale || 0.5,
    maxScale: options.maxScale || 5,
  }
  function point2D(x, y) {
    return { x, y }
  }
  const reqAnimationFrame = (() => {
    return (
      window[Hammer.prefixed(window, 'requestAnimationFrame')] ||
      // eslint-disable-next-line func-names
      function(callback) {
        window.setTimeout(callback, 1000 / 60)
      }
    )
  })()
  // a      b   c   d     e     f
  // x缩放，无，无，y缩放，x平移，y平移
  let tMatrix = [1, 0, 0, 1, 0, 0]
  let initScale = 1 // 初始化scale
  const hammerManager = new Hammer.Manager(element)
  let ticking = false
  let posCenter = point2D(0, 0) // 缓存双指的中心坐标
  let duration = '' // 设置过渡效果，用于双击缩放效果，用后移除
  let lastTranslate = point2D(0, 0) // 记录上次的偏移值
  let enabled = true // 是否允许滑动
  hammerManager.add(new Hammer.Pan({ threshold: 0, pointers: 1 }))
  hammerManager.add(new Hammer.Pinch({ threshold: 0 }))
  hammerManager.add(new Hammer.Tap({ event: 'doubletap', taps: 2 }))

  function updateElementTransform() {
    element.style.transition = duration
    const tmp = tMatrix.join(',')
    element.style.transform = `matrix(${tmp})`
    ticking = false
  }

  function requestElementUpdate(eventName) {
    if (!ticking) {
      reqAnimationFrame(updateElementTransform)
      ticking = true
    }
  }
  function boundary() {
    // 判断边界
    const { clientWidth, clientHeight } = document.documentElement
    if (tMatrix[4] > 0) {
      tMatrix[4] = 0
    }
    if (tMatrix[4] < -clientWidth * tMatrix[0] + clientWidth) {
      tMatrix[4] = -clientWidth * tMatrix[0] + clientWidth
    }
    if (tMatrix[5] > 0) {
      tMatrix[5] = 0
    }
    if (
      -1 * tMatrix[5] >
      element.offsetHeight * tMatrix[0] - clientHeight + 100
    ) {
      tMatrix[5] = (element.offsetHeight * tMatrix[0] - clientHeight + 100) * -1
    }
  }

  function onPanStart(ev) {
    if (!enabled) {
      return
    }
    lastTranslate = point2D(tMatrix[4], tMatrix[5]) // 缓存上一次的偏移值
  }

  function onPan(ev) {
    if (!enabled) {
      return
    }
    duration = ''
    tMatrix[4] = lastTranslate.x + ev.deltaX
    tMatrix[5] = lastTranslate.y + ev.deltaY
    // 判断边界
    boundary()
    requestElementUpdate('onpan')
  }

  function onPinch(ev) {
    if (!enabled) {
      return
    }
    let nowScale = initScale * ev.scale
    // 缩放边界判断
    const minScaleBoundary = nowScale < options.minScale
    const maxScaleBoundary = nowScale > options.maxScale
    if (minScaleBoundary) {
      nowScale = options.minScale
      if (ev.additionalEvent === 'pinchin') {
        return false
      }
    } else if (maxScaleBoundary) {
      nowScale = options.maxScale
      if (ev.additionalEvent === 'pinchout') {
        return false
      }
    }
    // eslint-disable-next-line no-multi-assign
    tMatrix[0] = tMatrix[3] = nowScale
    if (ev.scale > 1) {
      tMatrix[4] =
        -1 * Math.abs(1 - ev.scale) * posCenter.x + lastTranslate.x * ev.scale
      tMatrix[5] =
        -1 * Math.abs(1 - ev.scale) * posCenter.y + lastTranslate.y * ev.scale
    } else {
      tMatrix[4] =
        Math.abs(1 - ev.scale) * posCenter.x + lastTranslate.x * ev.scale
      tMatrix[5] =
        Math.abs(1 - ev.scale) * posCenter.y + lastTranslate.y * ev.scale
    }

    boundary()
    requestElementUpdate('onpinch')
  }

  function onPinchStart(ev) {
    if (!enabled) {
      return
    }
    duration = ''
    lastTranslate = point2D(tMatrix[4], tMatrix[5]) // 记录上一次的偏移值
    initScale = tMatrix[0] || 1
    posCenter = point2D(ev.center.x, ev.center.y)

    requestElementUpdate('onpinchStart')
  }

  function onDoubleTap(ev) {
    if (!enabled) {
      return
    }
    duration = 'transform .25s ease-in-out'
    const nowScale = tMatrix[0]
    if (nowScale !== 1) {
      const pointer = ev.center
      // scale不等于1，要重回1
      const scale = 1
      tMatrix[4] =
        ((-Math.abs(pointer.x / 2) + Math.abs(tMatrix[4])) * -1) / tMatrix[0]
      tMatrix[5] =
        ((-Math.abs(pointer.y / 2) + Math.abs(tMatrix[5])) * -1) / tMatrix[0]
      // eslint-disable-next-line no-multi-assign
      tMatrix[0] = tMatrix[3] = scale
    } else {
      const pointer = ev.center
      const scale = 2
      // eslint-disable-next-line no-multi-assign
      tMatrix[0] = tMatrix[3] = scale
      tMatrix[4] =
        Math.abs(scale) * (Math.abs(pointer.x / 2) + Math.abs(tMatrix[4])) * -1
      tMatrix[5] =
        Math.abs(scale) * (Math.abs(pointer.y / 2) + Math.abs(tMatrix[5])) * -1
    }
    boundary()
    requestElementUpdate('doubleTap')
  }

  hammerManager.on('panmove', onPan)
  hammerManager.on('panstart', onPanStart)
  hammerManager.on('pinchmove', onPinch)
  hammerManager.on('pinchstart', onPinchStart)
  hammerManager.on('doubletap', onDoubleTap)

  requestElementUpdate()

  const resetMatrix = () => {
    tMatrix = [1, 0, 0, 1, 0, 0]
    requestElementUpdate('reset')
  }
  const changeMatrix = (x, y) => {
    tMatrix = [1, 0, 0, 1, -x, -y]
    requestElementUpdate('change')
  }
  const enable = () => {
    enabled = true
  }
  const disable = () => {
    enabled = false
  }
  return { resetMatrix, changeMatrix, enable, disable, tMatrix }
}
